/* eslint-disable max-len */
import PropTypes from 'prop-types';
import {
    PureComponent
} from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Link from 'Component/Link';
import { PREMIUM_CUSTOMER } from 'Route/ProductPage/ProductPage.config';
import { TotalsType } from 'SourceType/MiniCart';

import './CheckoutProgressBar.style';

/** @namespace ZnetPwa/Component/CheckoutProgressBar/Component/CheckoutProgressBarComponent */
export class CheckoutProgressBarComponent extends PureComponent {
    static propTypes = {
        cartTotals: TotalsType.isRequired,
        locations: PropTypes.shape({
            cart: PropTypes.string,
            shipping: PropTypes.string,
            billing: PropTypes.string,
            payment: PropTypes.string
        }).isRequired
    };

    render() {
        const {
            cartTotals,
            locations: {
                cart,
                shipping,
                billing
            }
        } = this.props;

        const isPremiumCustomer = cartTotals?.items?.length === 1 && cartTotals?.items?.[0]?.sku === PREMIUM_CUSTOMER;

        return (
            <ContentWrapper
              label="CheckoutProgress"
              mix={ { block: 'CheckoutProgressBar' } }
            >
            <div block="CheckoutProgressBar" elem="Wrapper">
                <div block="CheckoutProgressBar" elem="Cart" mods={ { status: cart } }>
                    <Link to="cart" title={ __('Shopping cart') }>
                        <span>
                            { __('Shopping cart') }
                        </span>
                    </Link>
                </div>
                <div block="CheckoutProgressBar" elem="Shipping" mods={ { status: shipping, isHidden: isPremiumCustomer } }>
                    <Link to="checkout/shipping" title={ __('Shipping') }>
                        <span>
                            { __('Shipping') }
                        </span>
                    </Link>
                </div>
                <div block="CheckoutProgressBar" elem="Billing" mods={ { status: billing } }>
                    <Link to="checkout/billing" title={ __('Billing & Payment') }>
                        <span>
                            { __('Billing & Payment') }
                        </span>
                    </Link>
                </div>
            </div>
            </ContentWrapper>
        );
    }
}

export default CheckoutProgressBarComponent;
