import {
    PureComponent
} from 'react';
import { connect } from 'react-redux';

import { mapStateToProps as sourceMapStateToProps } from 'Component/Link/Link.container';
import { TotalsType } from 'SourceType/MiniCart';
import { LocationType } from 'Type/Common';
import { appendWithStoreCode } from 'Util/Url';

import CheckoutProgressBar from './CheckoutProgressBar.component';

import './CheckoutProgressBar.style';

/** @namespace ZnetPwa/Component/CheckoutProgressBar/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace ZnetPwa/Component/CheckoutProgressBar/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    cartTotals: state.CartReducer.cartTotals
});
/** @namespace ZnetPwa/Component/CheckoutProgressBar/Container/CheckoutProgressBarContainer */
export class CheckoutProgressBarContainer extends PureComponent {
    static propTypes = {
        cartTotals: TotalsType.isRequired,
        location: LocationType.isRequired
    };

    state = {
        locations: [
            { cart: 'empty' },
            { shipping: 'empty' },
            { billing: 'empty' },
            { payment: 'empty' }
        ]
    };

    componentDidMount() {
        const { location: { pathname } } = this.props;
        const { locations } = this.state;

        const pureLocation = pathname.slice(pathname.lastIndexOf('/') + 1, pathname.length);
        const currentElem = locations.findIndex((elem) => Object.keys(elem)[0] === pureLocation);

        if (currentElem !== -1) {
            const newLocations = Array.from(locations);
            newLocations.reduce((acc, elem) => {
                if (acc < currentElem) {
                    newLocations[acc][Object.keys(elem)[0]] = 'completed';
                } else if (acc === currentElem) {
                    newLocations[acc][Object.keys(elem)[0]] = 'active';
                } else {
                    newLocations[acc][Object.keys(elem)[0]] = 'empty';
                }

                return acc + 1;
            }, 0);

            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ locations: newLocations });
        }
    }

    componentDidUpdate(prevProps) {
        const { location: { pathname: prevPath } } = prevProps;
        const { location: { pathname } } = this.props;
        const { locations } = this.state;

        if (prevPath !== pathname) {
            const pureLocation = pathname.slice(pathname.lastIndexOf('/') + 1, pathname.length);
            const currentElem = locations.findIndex((elem) => Object.keys(elem)[0] === pureLocation);

            if (currentElem !== -1) {
                const newLocations = Array.from(locations);

                newLocations.reduce((acc, elem) => {
                    if (acc < currentElem) {
                        locations[acc][Object.keys(elem)[0]] = 'completed';
                    } else if (acc === currentElem) {
                        locations[acc][Object.keys(elem)[0]] = 'active';
                    } else {
                        locations[acc][Object.keys(elem)[0]] = 'empty';
                    }

                    return acc + 1;
                }, 0);

                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({ locations: newLocations });
            }
        }
    }

    _locationsToObject() {
        const { locations } = this.state;

        const locationsAsObject = locations.reduce(
            (obj, item) => Object.assign(obj, { [Object.keys(item)[0]]: Object.values(item)[0] }),
            {}
        );

        return locationsAsObject;
    }

    containerProps = () => {
        const { cartTotals } = this.props;

        return {
            locations: this._locationsToObject(),
            cartTotals
        };
    };

    render() {
        const { location: { pathname } } = this.props;

        if (
            pathname !== appendWithStoreCode('/cart')
            && pathname !== (appendWithStoreCode('/checkout/shipping'))
            && pathname !== (appendWithStoreCode('/checkout/billing'))
            && pathname !== (appendWithStoreCode('/checkout/payment'))
        ) {
            return null;
        }

        return (
            <CheckoutProgressBar
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutProgressBarContainer);
