import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { isCrawler } from 'SourceUtil/Browser';

/** @namespace Purechat/Component/PureChat/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isPurechatActive: state.ConfigReducer.purechat_active,
    purechatWidgetkey: state.ConfigReducer.purechat_widgetkey
});

/** @namespace Purechat/Component/PureChat/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Purechat/Component/PureChat/Container/PureChatContainer */
export class PureChatContainer extends PureComponent {
    static propTypes = {
        purechatWidgetkey: PropTypes.string.isRequired,
        isPurechatActive: PropTypes.bool.isRequired,
        isVisible: PropTypes.bool.isRequired
    };

    state = { isDone: false };

    componentDidMount() {
        const { purechatWidgetkey, isPurechatActive } = this.props;

        // Not rendering chat if crawler visit
        if (isCrawler() === true || !isPurechatActive || !purechatWidgetkey) {
            return null;
        }

        return (
            <>
                { this.renderPureChat() }
            </>
        );
    }

    componentDidUpdate(prevProps) {
        this.hideChatOnStateChange(prevProps);
    }

    renderPCWidget(purechatWidgetkey) {
        const { isDone } = this.state;
        if (!isDone && (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete')) {
            // eslint-disable-next-line no-undef, no-new
            new PCWidget({ c: purechatWidgetkey, f: true });
            this.setState({ isDone: true });
        }
    }

    renderPureChat() {
        const { purechatWidgetkey } = this.props;
        const script = document.createElement('script');
        script.async = true;
        script.type = 'text/javascript';
        script.src = 'https://app.purechat.com/VisitorWidget/WidgetScript';
        document.getElementsByTagName('HEAD').item(0).appendChild(script);
        script.onload = () => this.renderPCWidget(purechatWidgetkey);
    }

    hideChatOnStateChange({ isVisible: prevIsVisible }) {
        const { isVisible } = this.props;

        if (isVisible !== prevIsVisible) {
            if (isVisible) {
                window?.purechatApi?.set('chatbox.visible', true);
                return;
            }

            if (!isVisible) {
                window?.purechatApi?.set('chatbox.visible', false);
            }
        }
    }

    render() {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PureChatContainer);
